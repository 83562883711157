





































import { defineComponent, watch } from "@vue/composition-api";
import FormModel from "@/apps/core/models/formModel";
import { KeterampilanFM } from "../models/tindakan";
import { Komponen } from "../models/komponen";
import { PemeriksaanFM } from "../models/pemeriksaan";
import { SaveContext } from "@/apps/core/models/formModel";
import { TatalaksanaFM } from "../models/tatalaksana";
import useFormModel from "@/apps/core/modules/useFormModel";

const FormGetterMap: Record<string, Function> = {
  pemeriksaan: () => new PemeriksaanFM(),
  tatalaksana: () => new TatalaksanaFM(),
  keterampilan: () => new KeterampilanFM(),
};

export default defineComponent({
  name: "KomponenModalForm",
  props: { headerText: { type: String, required: true }, namaKomponen: String },
  setup(props, { emit }) {
    const komponenStr = props.headerText.toLowerCase();
    const form = FormGetterMap[komponenStr]() as FormModel;
    const composition = useFormModel(form);
    const instance = composition.instance as Komponen;

    watch(
      () => props.namaKomponen,
      (newValue) => {
        if (newValue) {
          instance.nama = newValue;
          composition.validate("nama");
        }
      }
    );

    const saveForm = async () => {
      await composition.formRef.save(SaveContext.Create, false);
      if (instance.id) {
        const obj = { id: instance.id, nama: instance.nama };
        emit("close", obj);
      }
    };

    return {
      // Composition
      ...composition,

      // Method
      saveForm,
    };
  },
});
