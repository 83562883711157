import FormModel from "@/apps/core/models/formModel";
import { Komponen, KomponenAPI } from "./komponen";

class PemeriksaanFM extends FormModel {
  constructor() {
    super(
      new KomponenAPI("/penyakit/pemeriksaan/"),
      new Komponen(),
      ["nama"],
      []
    );
  }
}

export {
  PemeriksaanFM,
  // ListPemeriksaanAPI,
  // ListPemeriksaanVM,
};

// import { API } from "@/apps/core/api";
// import ListAPI from "@/apps/core/api/list";
// import ViewListModel from "@/apps/core/models/viewListModel";

// class PemeriksaanAPI extends API {
//   static modelPath = "/penyakit/pemeriksaan/";

//   constructor() {
//     super(PemeriksaanAPI.modelPath);
//   }
// }

// class ListPemeriksaanAPI extends ListAPI {
//   constructor() {
//     super(PemeriksaanAPI.modelPath);
//   }
// }

// class ListPemeriksaanVM extends ViewListModel {
//   constructor() {
//     super(new ListPemeriksaanAPI());
//   }

//   getInstance(json: Record<string, any>) {
//     const pemeriksaan = new Komponen();
//     pemeriksaan.loadData(json);
//     return pemeriksaan;
//   }
// }
