import FormModel from "@/apps/core/models/formModel";
import { Komponen, KomponenAPI } from "./komponen";

class TatalaksanaFM extends FormModel {
  constructor() {
    super(
      new KomponenAPI("/penyakit/tatalaksana/"),
      new Komponen(),
      ["nama"],
      []
    );
  }
}

export {
  TatalaksanaFM,
  // ListTatalaksanaAPI,
  // ListTatalaksanaVM,
};

// import { API } from "@/apps/core/api";
// import ListAPI from "@/apps/core/api/list";
// import PlainModel from "@/apps/core/models/plainModel";
// import ViewListModel from "@/apps/core/models/viewListModel";

// class Tatalaksana extends PlainModel {
//   kode: string | null = null;
//   nama: string | null = null;
// }

// class TatalaksanaAPI extends API {
//   static modelPath = "/penyakit/tatalaksana/";

//   constructor() {
//     super(TatalaksanaAPI.modelPath);
//   }
// }

// class ListTatalaksanaAPI extends ListAPI {
//   constructor() {
//     super(TatalaksanaAPI.modelPath);
//   }
// }

// class ListTatalaksanaVM extends ViewListModel {
//   constructor() {
//     super(new ListTatalaksanaAPI());
//   }

//   getInstance(json: Record<string, any>) {
//     const tatalaksana = new Tatalaksana();
//     tatalaksana.loadData(json);
//     return tatalaksana;
//   }
// }
