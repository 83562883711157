import FormModel from "@/apps/core/models/formModel";
import { Komponen, KomponenAPI } from "./komponen";

class KeterampilanFM extends FormModel {
  constructor() {
    super(new KomponenAPI("/penyakit/tindakan/"), new Komponen(), ["nama"], []);
  }
}

export { KeterampilanFM };

// import { API } from "@/apps/core/api";

// class Tindakan extends PlainModel {
//   nama: string | null = null;
// }

// class TindakanAPI extends API {
//   static modelPath = "/penyakit/tindakan/";

//   constructor() {
//     super(TindakanAPI.modelPath);
//   }
// }

// class TindakanFM extends FormModel {
//   constructor() {
//     super(new TindakanAPI(), new Tindakan(), ["nama"], []);
//   }
// }
